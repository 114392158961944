import axios from '../../axios_settings'
import { createContext, useState } from 'react'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import jwtInterceptor from './jwtInterceptor'

const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    if (localStorage.getItem('tokens')) {
      let tokens = JSON.parse(localStorage.getItem('tokens'))
      return jwt_decode(tokens.access)
    }
    return null
  })
  const navigate = useNavigate()
  const getUserData = async () => {
    return await jwtInterceptor.get('/api/reti-app/user-info/')
  }
  const login = async (payload) => {
    const response = await axios.post('/api/token/', payload)
    if (response) {
      localStorage.setItem('tokens', JSON.stringify(response.data))
      setUser(jwt_decode(response.data.access))
      navigate('/')
    }
    navigate('/login')
  }
  const register = async (payload) => {
    const response = await axios.post('/api/register/', {
      username: payload.username,
      password: payload.password,
      regulations_accepted: payload.regulations_accepted,
    })
    return response
  }
  const logout = async () => {
    localStorage.removeItem('tokens')
    setUser(null)
    navigate('/login')
  }
  return (
    <AuthContext.Provider value={{ user, login, logout, getUserData, register }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
