import { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  TextField,
  Container,
  ThemeProvider,
  createTheme,
  Button,
  CssBaseline,
} from '@mui/material'

import RetiLogo from '../components/RetiLogo/RetiLogo'
import { passwordRegex } from '../consts/regex'
import { ErrorMessage } from './PasswordRecovery'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from '../axios_settings'
import { REQUEST_ERROR_MESSAGE } from '../consts/emailResetMessages'
import { PASSWORD_MATCH_ERROR, PASSWORD_ERROR } from '../consts/newPasswordMessages'

const theme = createTheme()

const isCorrectPassword = (password) => {
  if (password !== '') {
    if (!password.match(passwordRegex)) return false
    return true
  }
  return true
}

const getParams = (pathname) => {
  const splittedPathname = pathname.split('/')
  return [splittedPathname[2]]
}

export const NewPassword = () => {
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [success, setSuccess] = useState(false)
  const [requestError, setRequestError] = useState('')

  const location = useLocation()
  const [token] = getParams(location.pathname)

  const submitForm = async (e) => {
    e.preventDefault()
    try {
      const result = await axios.post(`/api/reset-password-confirm/${token}/`, {
        new_password: newPassword,
        confirm_password: confirmPassword,
      })
      if (result.status === 200) setSuccess(true)
    } catch (error) {
      setRequestError(REQUEST_ERROR_MESSAGE)
    }
  }

  const isNewPasswordCorrect = isCorrectPassword(newPassword)
  const newPasswordError = isNewPasswordCorrect ? '' : PASSWORD_ERROR
  const isPasswordMatch = newPassword === confirmPassword
  const isPasswordMatchMessage =
    confirmPassword !== '' && !isPasswordMatch ? PASSWORD_MATCH_ERROR : ''

  const isButtonDisabled =
    (newPassword === '' && confirmPassword === '') || !isNewPasswordCorrect || !isPasswordMatch

  const navigate = useNavigate()

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        navigate('/login')
      }, 5000)
    }
  }, [success])

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <RetiLogo />
          <Typography component="h1" variant="h5">
            Set new password
          </Typography>
          <Box component="form" noValidate onSubmit={submitForm} mt={3} sx={{ width: '100%' }}>
            {!success ? (
              <>
                <TextField
                  required
                  fullWidth
                  name="newPassword"
                  label="New password"
                  type="password"
                  id="newPassword"
                  autoComplete="password"
                  onChange={(e) => setNewPassword(e.target.value)}
                  value={newPassword}
                  placeholder="Please provide new password"
                />
                <ErrorMessage message={newPasswordError} />
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm password"
                  type="password"
                  id="confirmPassword"
                  autoComplete="password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                  placeholder="Please confirm new password"
                  sx={{ mt: 2 }}
                />
                <ErrorMessage message={isPasswordMatchMessage} />
                <ErrorMessage message={requestError} />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                  }}
                  disabled={isButtonDisabled}
                >
                  Set new password
                </Button>
              </>
            ) : (
              <Typography component="h2" variant="h6" textAlign="center" fontWeight="normal">
                Request succeeded, you will be redirected to login page
              </Typography>
            )}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
