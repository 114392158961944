import axios from '../../axios_settings'

const jwtInterceptor = axios.create({})

jwtInterceptor.interceptors.request.use((config) => {
  let tokensData = JSON.parse(localStorage.getItem('tokens'))
  config.headers.common['Authorization'] = `Bearer ${tokensData.access}`
  return config
})

jwtInterceptor.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response.status === 401) {
      const authData = JSON.parse(localStorage.getItem('tokens'))
      const payload = {
        refresh: authData.refresh,
      }

      let apiResponse = await axios.post('/api/token/refresh/', payload)
      localStorage.setItem('tokens', JSON.stringify(apiResponse.data))
      error.config.headers['Authorization'] = `bearer ${apiResponse.data.access}`
      return axios(error.config)
    } else {
      return Promise.reject(error)
    }
  }
)

export default jwtInterceptor
