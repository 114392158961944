import { Container, createTheme, CssBaseline, ThemeProvider, Box, Typography } from '@mui/material'
import RetiLogo from '../components/RetiLogo/RetiLogo'
import { useEffect, useState } from 'react'
import axios from '../axios_settings'
import { useNavigate } from 'react-router-dom'

const theme = createTheme()

const REQUEST_IN_PROGRESS = 'Account activation in progress'
const REQUEST_SUCCESS = 'Request succeeded, you will be redirected to login page'

export const ConfirmRegistration = () => {
  const userId = location.pathname.split('/')[2]

  const [message, setMessage] = useState(REQUEST_IN_PROGRESS)

  const navigate = useNavigate()

  useEffect(() => {
    axios
      .get(`/api/confirm-registration/${userId}/`)
      .then(() => {
        setMessage(REQUEST_SUCCESS)
        setTimeout(() => {
          navigate('/login')
        }, 5000)
      })
      .catch((e) => setMessage(e.response.data.error))
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <RetiLogo />
          <Typography component="h2" variant="h6" textAlign="center" fontWeight="normal">
            {message}
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
