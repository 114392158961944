import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Login from './pages/Login'
import { AuthContextProvider } from './components/shared/AuthContext'
import ProtectedRoute from './components/shared/ProtectedRoute'
import NavbarMenu from './components/shared/NavbarMenu'
import Signup from './pages/Signup'
import { BinanceContextProvider } from './components/shared/BinanceContext'
import { NordigenContextProvider } from './components/shared/NordigenContext'
import ConfirmedNordigenIntegration from './components/NordigenIntegration/ConfirmedNordigenIntegration'
import SummaryOfPurchases from './components/SummaryOfPurchases/SummaryOfPurchases'
import PasswordRecovery from './pages/PasswordRecovery'
import { NewPassword } from './pages/NewPassword'
import { ConfirmRegistration } from './pages/ConfirmRegistration'

function App() {
  return (
    <AuthContextProvider>
      <BinanceContextProvider>
        <NordigenContextProvider>
          <NavbarMenu>
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <ProtectedRoute accessBy="authenticated">
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/savings-to-crypto-app/confirm-nordigen-activation-view/"
                element={
                  <ProtectedRoute accessBy="authenticated">
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/confirmed-nordigen-activation/"
                element={
                  <ProtectedRoute accessBy="authenticated">
                    <ConfirmedNordigenIntegration />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/summary-of-purchases/"
                element={
                  <ProtectedRoute accessBy="authenticated">
                    <SummaryOfPurchases />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/login"
                element={
                  <ProtectedRoute accessBy="guest">
                    <Login />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/register"
                element={
                  <ProtectedRoute accessBy="guest">
                    <Signup />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/password-recovery"
                element={
                  <ProtectedRoute accessBy="guest">
                    <PasswordRecovery />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/reset-password-confirm/:token"
                element={
                  <ProtectedRoute accessBy="guest">
                    <NewPassword />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/confirm-registration/:uuid"
                element={
                  <ProtectedRoute accessBy="guest">
                    <ConfirmRegistration />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </NavbarMenu>
        </NordigenContextProvider>
      </BinanceContextProvider>
    </AuthContextProvider>
  )
}

export default App
